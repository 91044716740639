import React from 'react';
import image from '../assets/images/description-img.png';

function Description() {
	return (
		<div className='section section-description'>
			<div className='container'>
				<div className='grid grid-col-2 grid-gap-2'>
					<div className='image'>
						<div className='stretchy-wraper wrapper-50'>
							<img src={image} alt='image' />
						</div>
					</div>
					<div className='info'>
						<p className='roboto text'>
							Levenhammer Bier - reicher Geschmack und
							Sortenvielfalt.
						</p>
						<p className='roboto text'>
							Dieses Getränk verdient es, als Bier mit Charakter
							bezeichnet zu werden. Nicht umsonst sind am Ufer ein
							Löwe und ein Hammer abgebildet.
						</p>
						<p className='roboto text with-logo'>
							Leo - charakterisiert das Vertrauen des Königs der
							Tiere, den Hammer - die Stärke eines gehärteten
							Charakters.
						</p>
						<p className='roboto text'>
							Probieren Sie Levenhammer-Bier und Sie werden die
							wahre Stärke des Malzes und den Hopfencharakter
							spüren.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Description;
