import React from 'react';
import barrel from '../assets/images/barrel.svg';
import beer from '../assets/images/beer.svg';
import doc from '../assets/images/doc.svg';
import ScrollAnimation from 'react-animate-on-scroll';



function Historical() {
	return (
		<div className='section section-historical section-blue'>
			<div className='container'>
				<h2 className='text text-header pirata text-centred title'>
					Historische Tatsachen
				</h2>

				<div className='grid grid-col-3 grid-gap-4 mg-tp-4'>
					<ScrollAnimation animateIn='animate__fadeInUp' delay={0}>

					<div className='fact'>
						<img src={barrel} alt='' />
						<p className='text roboto mg-tp-2'>
							Levenhammer wird in der OeTTINGER Brauerei
							(OETTINGER Brauerei GmbH) in Mönchengladbach
							Brauerei, Deutschland, gebraut. Die Tradition des
							Unternehmens reicht bis ins mittelalterliche Europa
							zurück.
						</p>
					</div>
					</ScrollAnimation>
					<ScrollAnimation animateIn='animate__fadeInUp' delay={500}>
					<div className='fact'>
						<img src={beer} alt='' />
						<p className='text roboto mg-tp-2'>
							Die erste urkundliche Erwähnung einer von der
							Familie von Etinger gegründeten Brauerei stammt aus
							dem Jahr 1333.
						</p>
					</div>
					</ScrollAnimation>
					<ScrollAnimation animateIn='animate__fadeInUp' delay={1000}>
					<div className='fact'>
						<img src={doc} alt='' />
						<p className='text roboto mg-tp-2'>
							Heute werden die Getränke des Unternehmens in
							modernsten Anlagen nach dem Reinheitsgebot von 1516
							gebraut. Dieses Gesetz regelt seit fünfhundert
							Jahren die Bierproduktion.
						</p>
					</div>
					</ScrollAnimation>
				</div>
			</div>
		</div>
	);
}

export default Historical;
