import React from 'react';
import botles from '../assets/images/main-beer.png';
import logo from '../assets/images/logo.png';

function Main() {
	return (
		<div className='section section-main'>
			<div className='container'>
				<div className='grid grid-col-2'>
					<div className='main-data'>
						<div className='logo centred'>
							<img src={logo} alt='' />
						</div>
						<p className='mg-tp-2 text text-centred roboto before before-quote main-description'>
							Bier mit starkem Charakter
						</p>
					</div>
					<div className='stretchy-wraper wrapper-50'>
						<img src={botles} alt='botles' />
					</div>
				</div>
			</div>
		</div>
	);
}

export default Main;
