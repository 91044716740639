import React from 'react';

function Footer() {
	return (
		<div className='section section-footer section-blue'>
			<div className='container'>
				<div className='grid grid-col-6'>
					<div className='roboto text'>
						<p>
							Brauerei Monchengladbach, Senefelderstrasse 29,
							41066 Monchengladbach
						</p>
						<p>
							Export Verkaufsmanager LowenhammerBrauerei@gmail.com
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Footer;
