import React from 'react';
import Beer from './components/Beer';
import Description from './components/Description';
import Footer from './components/Footer';
import Historical from './components/Historical';
import Main from './components/Main';

import { Hoc } from './Hoc';
// WinnersComponent

function IndexPage() {
	return (
		<>
			<Main />
			<div className='bg-container'>
				<Description />
				<Historical />
			</div>
			<Beer />
			<Footer />
		</>
	);
}
export default Hoc(IndexPage);
