import React from "react";
import IndexPage from "./IndexPage";
import "./assets/scss/main.scss";
// import NavJS from "./components/NavJS";
// import FooterComponent from "./components/FooterComponent";

export default class App extends React.Component {
  render() {
    
    return (
      <div className="app">
        {/* <NavJS /> */}
        <IndexPage />
        {/* <FooterComponent /> */}
      </div>
    );
  }
}
