import React from 'react';
import bottle1 from '../assets/images/beer/1.png';
import logo1 from '../assets/images/beer/logo1.png';
import bottle2 from '../assets/images/beer/2.png';
import logo2 from '../assets/images/beer/logo2.png';
import bottle3 from '../assets/images/beer/3.png';
import logo3 from '../assets/images/beer/logo3.png';
import bottle4 from '../assets/images/beer/4.png';
import logo4 from '../assets/images/beer/logo4.png';
import ScrollAnimation from 'react-animate-on-scroll';

function Beer() {
	return (
		<div className='section section-beer'>
			<div className='container'>
				<div className='grid grid-col-4 grid-gap-2'>
					<ScrollAnimation animateIn='animate__fadeInUp'>
						<div className='beer-bottle'>
							<div className='bottle-container'>
								<img src={bottle1} alt='' />
							</div>
							<div className='logo centred mg-tp-3'>
								<img src={logo1} alt='' />
							</div>
							<p className='text text-gray text-centred mg-tp-1 roboto'>
								Dunkelfiltriertes Bier verleiht einen milden,
								reichen Geschmack und ein erfrischendes harmonisches
								Aroma mit einem Hauch von Gerste. Das Getränk
								enthält reines Trinkwasser, Gerstenmalz,
								Hopfenextrakt
							</p>
						</div>
					</ScrollAnimation>
					<ScrollAnimation animateIn='animate__fadeInUp' delay={250}>
						<div className='beer-bottle'>
							<div className='bottle-container'>
								<img src={bottle2} alt='' />
							</div>
							<div className='logo centred mg-tp-3'>
								<img src={logo2} alt='' />
							</div>
							<p className='text text-gray text-centred mg-tp-1 roboto'>
								Gefiltertes hellgoldenes Bier mit angenehmem
								Gerstenaroma und erfrischendem Geschmack. Es enthält
								reines Trinkwasser, Gerstenmalz, Hopfenextrakt
							</p>
						</div>
					</ScrollAnimation>
					<ScrollAnimation animateIn='animate__fadeInUp' delay={500}>
						<div className='beer-bottle'>
							<div className='bottle-container'>
								<img src={bottle3} alt='' />
							</div>
							<div className='logo centred mg-tp-3'>
								<img src={logo3} alt='' />
							</div>
							<p className='text text-gray text-centred mg-tp-1 roboto'>
								Gefiltertes Bier mit angenehmem Goldton,
								erfrischendem Aroma und harmonischem
								Hopfengeschmack. Das Getränk enthält reines
								Trinkwasser, Gerstenmalz, Hopfenextrakt
							</p>
						</div>
					</ScrollAnimation>
					<ScrollAnimation animateIn='animate__fadeInUp' delay={750}>
						<div className='beer-bottle'>
							<div className='bottle-container'>
								<img src={bottle4} alt='' />
							</div>
							<div className='logo centred mg-tp-3'>
								<img src={logo4} alt='' />
							</div>
							<p className='text text-gray text-centred mg-tp-1 roboto'>
								Leichtes unfiltriertes Bier mit reichem Geschmack
								und mildem Aroma mit leichter Bitterkeit. Das
								Getränk enthält reines Trinkwasser, Weizenmalz,
								Gerstenmalz, Hopfenextrakt, Hefe
							</p>
						</div>
					</ScrollAnimation>
				</div>
			</div>
		</div>
	);
}

export default Beer;
